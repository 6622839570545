.babel-links {
    &__toggle {
        border: 0;
        padding: 5px 40px 5px 20px;
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        border-left: 1px solid rgba(255,255,255,.15);
        background-color: transparent;
        margin: 0 0 0 20px;
        position: relative;
        float: right;
        text-align: left;
        width: 95px;
        height: 34px;
        color: #fff;
        cursor: pointer;
        transition: .25s color ease;
        position: relative;

        &::before,
        &::after {
            content: '';
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
        }

        &::before {
            position: relative;
            width: 35px;
            height: 24px;
            left: 0;
            top: 0;
        }

        &::after {
            position: absolute;
            background-image: url(../img/chevron-down.png);
            width: 10px;
            height: 10px;
            top: 50%;
            margin-top: -5px;
            right: 20px;
            transition: .25s transform ease;
            transform: rotate(0deg);
        }

        &--en::before {
            background-image: url(../img/flags/flag-en.png);
        }

        &--de::before {
            background-image: url(../img/flags/flag-de.png);
        }

        &--pt::before {
            background-image: url(../img/flags/flag-pt.png);
        }

        &--nl::before {
            background-image: url(../img/flags/flag-nl.png);
        }

        &:hover,
        &:focus,
        &.is_pressed {
            color: var(--orange);
        }

        &.is_pressed::after {
            transform: rotate(180deg);
        }

        @media (--until-tablet) {
            width: 55px;
            height: 14px;
            margin-left: 10px;
            padding: 0 0 0 10px;

            &::before {
                position: relative;
                width: 25px;
                height: 100%;
            }

            &::after {
                right: 0;
            }
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        position: absolute;
        top: 62px;
        right: 28px;
        background-color: #000;
        padding: 20px;
        transition: .25s opacity ease, .25s transform ease, .25s z-index ease;
        opacity: 0;
        z-index: -1;
        transform: translate(0,-20px) scale(.9);
        min-width: 120px;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background-color: #000;
            transform: rotate(-45deg);
            position: absolute;
            top: -5px;
            left: 50%;
            margin-left: -5px;
        }

        &.is_active {
            transform: translate(0,0) scale(1);
            opacity: 1;
            z-index: 10;
        }

        @media (--until-desktop) {
            right: 20px;
        }

        @media (--tablet) {
            top: 9px;
        }

        @media (--until-tablet) {
            &::before {
                left: auto;
                right: 27px;
                margin-left: 0;
            }
        }
    }

    &__item {
        display: block;

        &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid rgba(255,255,255,.15);
        }
    }

    &__language {
        display: block;
        color: #fff;
        transition: .25s color ease;

        &:hover,
        &:focus,
        &.is_active {
            color: var(--orange);
            outline: 0;
        }
    }
}
