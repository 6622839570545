.results__print,
.results__answers {
    float: left;
    clear: left;
    width: 40%;
}

.results__print {
    background-color: rgba(0,0,0,.5);
    color: #fff;
    padding: 20px;
    font-size: 16px;
    line-height: 25px;
    border-radius: 6px;
    margin-top: 30px;
    font-weight: normal;

    @media (--mobile-large) {
        margin-bottom: 30px;
    }

    @media (--mobile) {
        margin-bottom: 30px;
    }
}

.results__answers,
.results__recommendations {
    text-shadow: 1px 1px 2px rgba(0,0,0,.6);
}

.results__recommendations {
    float: right;
    clear: right;
    width: 55%;
}

.results__list {
    margin: 0 0 20px;
    padding: 0 0 0 20px;
}

.results__item {
    margin: 0 0 10px;
}

.results__text {
    margin: 0 0 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.results__factsheet {
    appearance: none;
    display: block;
    transition: .25s background-color ease;
    line-height: 40px;
    border-radius: 20px;
    height: 40px;
    background-color: #fff;
    color: var(--grey);
    text-transform: uppercase;
    font-weight: bold;
    width: 240px;
    text-align: center;
    padding: 0;
    margin-top: 20px;
    border: 2px solid #fff;
    float: left;
    clear: both;
    text-shadow: none;

    &:focus {
        border: 2px solid #000;
        outline: 0;
    }

    &:hover {
        background-color: rgba(255,255,255,.5);
    }

    @media (--until-tablet) {
        margin-top: 20px;
    }
}

.highlight {
    color: var(--orange);
}
